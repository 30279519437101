import React, { Suspense, useState, useEffect } from "react";
import * as api from "../../../services/services";
import { useAuth } from "../../../provider";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import ButtonMain from "../../../components/ButtonMain/ButtonMain";
import Card from "../../../components/Card/Card";
import "./signIn.scss";
import InputBlock from "../../../components/InputBlock/InputBlock";
import Loading from "../../../components/Loading/Loading";
import ErrorDialog from "../../../components/ErrorDialog/ErrorDialog";

import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import ModalBox from "../../../components/ModalBox/ModalBox";
import { useCustomUseLocation } from "../../../lastLocationProvider";

const SignIn = () => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [errorMessage, setErrorMessage] = useState(null);
	const [errorStatus, setErrorStatus] = useState(null);
	const [newAccountModal, setNewAccountModal] = useState(null);

	const { handleLogin, setUserData, setUserResponse, userResponse, setListOfClinic, setUserToken, setUserUserToken, state } = useAuth();
	const { stateLocation, deleteLastLocation } = useCustomUseLocation()

	const query = useQuery()
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}

	useEffect(() => {
		const userToken = query.get("userToken")
		const auth = query.get("auth")
		if (userToken && auth) {
			setUserToken(userToken)
			localStorage.setItem('userToken', userToken)
			localStorage.setItem('token', auth)
		}
	}, [])

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 *
	 * @param {object} values
	 * @see {setUserData}
	 * @see {setLoading}
	 * @see {handleLogin}
	 * @description Recibe un objeto que contiene values (NIF y password). Gestiona la llamada a la función login de services (API externa).
	 * Si esta devuelve un usuario se comprueba si el password ha sido reseteado. Si no ha sido reseteado muestra el modal de resetear password
	 * Además, si el usuario es nuevo se muestra un modal de bienvenida
	 * Si todo lo anterior es incorrecto (credenciales incorrectas) se crea un mensaje de error
	 */

	const signInHandler = async (values) => {
		if (!values) return;

		try {
			if (userResponse) {
				setLoading(true);
			}
			///73156352E /  Bavi777 
			const data = {
				User: values.User.trim(),
				Password: values.Password.trim(),
			};
			let response = await api.login(data);
			setListOfClinic(response.user);
			let newResponse = {
				user: {
					idClinica: response.user[0].ClinicId,
					idPaciente: response.user[0].PatientId,
					isPasswordReset: response.user[0].IsPasswordReset,
					isnewUser: response.user[0].IsNewUser,
					userToken: response.user[0].UserToken,
				}
			}
			setUserToken(newResponse.user.userToken)
			localStorage.setItem('userToken', newResponse.user.userToken);
			await setUserUserToken(newResponse.user.userToken);
			const setUserRes = await api.getUserData(newResponse.user);
			await setUserResponse(newResponse);
			await setUserData(setUserRes);
			await handleLogin(newResponse);
			if (newResponse.idPaciente) {
				if (
					newResponse.isPasswordReset == true ||
					newResponse.isPasswordReset === "true" ||
					newResponse.isPasswordReset == 1
				) {
					history.push({ pathname: "/intro/cambiarpassword" });
				} else if (
					newResponse.isnewUser == true ||
					newResponse.isnewUser === "true" ||
					newResponse.isnewUser == 1
				) {
					history.push("/intro/cambiarpassword");
				} else {
					const url = stateLocation.lastLocation
					deleteLastLocation()
					url ? history.push(url) : history.push("/Paciente/Cuenta");
				}
			}
		} catch (error) {
			setErrorMessage(error.message);
			setErrorStatus(error.status);
			setLoading(false);
		}
	};

	/**
	 *
	 * @description Si hay un error devuelve un mensaje
	 *
	 */

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	const renderErrors = () =>
		errorMessage || errorStatus ? <ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} /> : null;

	/**
	 *
	 * @description Esquema de validación del formulario
	 * @see {renderFormik}
	 *
	 */

	const validationSchema = yup.object({
		User: yup
			.string()
			.required("El campo NIF/NIE es obligatorio")
			.matches(
				/^((([X-Z])|([LM])){1}([-]?)([0-9]{7})([-]?)([a-zA-Z]{1}))|(([0-9]{8})([-]?)([a-zA-Z]))/,
				"El formato de NIF/NIE es incorrecto"
			),
		Password: yup.string().required("La contraseña es obligatoria"),
	});

	/**
	 *
	 * @returns {Component}
	 * @description Genera un componente formulario con la librería Formik que valida los valores User y Password
	 *
	 */

	const renderFormik = () => {
		return loading ? (
			<Loading />
		) : (
			<Formik
				//{ User: "Z9999999H", Password: "Baviera23" }
				initialValues={process.env.NODE_ENV === 'development' ? { User: "Z9999999H", Password: "Baviera23" } : { User: "", Password: "" }}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					signInHandler(values);
				}}
			>
				{(props) => {
					return (
						<Form onKeyUp={(e) => (e.key === 13 ? props.onSubmit : null)}>
							{renderErrors()}

							{/* Nif form group */}

							<div
								className="form-group"
								onClick={() => {
									setErrorStatus(null);
									setErrorMessage(null);
								}}
							>
								<InputBlock
									label="NIF/NIE"
									type="text"
									placeholder="Ejemplo 00000000X"
									handleChange={props.handleChange}
									paramChange="User"
									values={props.values.User}
									handleBlur={props.handleBlur}
									paramBlur="User"
								/>

								{props.touched.User && props.errors.User ? (
									<ErrorDialog text={props.errors.User} />
								) : null}
							</div>

							{/* Contraseña form group */}
							<div
								className="form-group password-group"
								onClick={() => {
									setErrorStatus(null);
									setErrorMessage(null);
								}}
							>
								<InputBlock
									label="Contraseña"
									type="password"
									placeholder="*********"
									handleChange={props.handleChange}
									paramChange="Password"
									values={props.values.Password}
									handleBlur={props.handleBlur}
									paramBlur="Password"
								/>
								{props.touched.Password && props.errors.Password ? (
									<ErrorDialog text={props.errors.Password} />
								) : null}{" "}
							</div>

							{/* Acciones del formulario */}

							<div className="form-actions"></div>
							<ButtonMain
								label="Acceder"
								iconClass="arrow-right-white"
								type="submit"
								action={props.handleSubmit}
							/>

							<Link className="forget-password" to="/intro/recuperarpassword">
								¿Has olvidado tu contraseña?
							</Link>
						</Form>
					);
				}}
			</Formik>
		);
	};

	const renderNewAccountModal = () => {
		const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox onToggle={setNewAccountModal}>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setNewAccountModal}>
					<div className="success-text" style={{ maxWidth: "80vw" }}>Si aún no tienes cuenta ponte en contacto con tu clínica.</div>
				</LazyModal>
			</Suspense>
		);
	};

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	/***
	 *
	 * @return {Component}
	 * @see {renderFormik}
	 * @description Render el formulario de Formik
	 *
	 */

	return (
		<React.Fragment>
			{newAccountModal ? renderNewAccountModal() : null}

			<Card customClass="signin-card">
				<h1>
					Accede a tu <span>Área Paciente</span>
				</h1>
				{/* Renderizado de formulario  */}

				{renderFormik()}
			</Card>
			<a onClick={() => setNewAccountModal(true)}>¿No tienes cuenta?</a>
		</React.Fragment>
	);
};

export default SignIn;
